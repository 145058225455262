.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Add these styles to your stylesheet or in your component's style tag */

.accordion-container {
  margin: 10px 0;
  /* Add margin as per your design */
  /* background-color: #f5f5f5; */
  /* Add your desired background color */
}

.accordion-summary {
  /* background-color: #e0e0e0; */
  /* Add your desired background color */
  font-size: 1.2rem;
  /* Add your desired font size */
  font-weight: bold;
  /* Add bold styling */
}

.accordion-summary-text {
  margin-right: 10px;
  /* Add margin as per your design */
}

.accordion-details {
  padding: 10px;
  /* Add padding as per your design */
  /* background-color: #fff; */
  /* Add your desired background color */
}

.bg-green {
  background-color: #2cd074;
}
.text-green {
  color: #2cd074;
}
.text-green-dark {
  color: #34714e;
}

/* Buttons */
.btn {
  @apply font-bold py-2 px-4 rounded;
}

.btn-green {
  @apply bg-green-500  text-white;
}

.btn-green:hover {
  @apply bg-green-700;
}
