@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Exo:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: 'Exo' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.app-container {
  /* height: calc(100vh - 3rem); */
}

.pages {
  width: calc(100vw - 88px);
  margin-left: 4rem;
}

.wanted-warning {
  animation: wanted 0.5s infinite;
  box-sizing: content-box;
  width: 20rem;
  height: 20rem;
  border: 10px solid red;
}

.image-delete-icon {
  display: none !important;
}

.image-container:hover .image-delete-icon {
  display: block !important;
}

@keyframes wanted {
  0% {
    border-color: red;
  }

  50% {
    border-color: transparent;
  }

  100% {
    border-color: red;
  }
}

.custom-ant-select .ant-select .ant-select-selector {
  border: 0px !important;
  background: transparent;
}
.dark .ant-select-selection-item {
  color: white;
}

.dark .ant-picker-range-separator {
  color: white;
}

:where(.css-dev-only-do-not-override-1okl62o).ant-select-outlined:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 0px solid transparent; /* Set border to 0px and color to transparent */
  background: transparent; /* Set background to transparent */
}

:where(.css-dev-only-do-not-override-1okl62o).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0px;
}
